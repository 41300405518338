






























































import PageTitle from '@/components/PageTitle.vue'
import { Style } from '@/includes/types/Post.types'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import Component from 'vue-class-component'
import Vue from 'vue'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    PageTitle,
    EmptyData
  }
})
export default class BoardStylesList extends Vue {
  stylesList: Record<string, Style> = {}

  isPopupOpen = false

  isMounted = false

  isLoading = false

  get sortedStyles(): Record<string, Style> {
    const styleKeys = Object.keys(this.stylesList).sort((a, b) => a.localeCompare(b))
    const styleDict = {}
    styleKeys.forEach(k => styleDict[k] = this.stylesList[k])
    return styleDict
  }

  toStyleCreatePage(): void {
    this.$router.push({
      name: 'Style',
      params: {
        actionType: 'new'
      },
    })
  }

  toStyleEditPage(styleId: string): void {
    this.$router.push({
      name: 'Style',
      params: {
        actionType: 'edit',
      },
      query: {
        styleId
      }
    })
  }

  toStyleCopyPage(styleId:string):void {
    this.$router.push({
      name: 'Style',
      params: {
        actionType: 'copy',
      },
      query: {
        styleId
      }
    })
  }

  removeStyleWarn(styleId: string): void {
    this.$confirm({
      title: this.$t('remove_style_warn_title').toString(),
      content: this.$t('remove_style_warn_text').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      centered: true,
      onOk: () => {
        this.removeStyle(styleId)
      }
    })
  }

  removeStyle(styleId: string): void {
    this.isLoading = true
    this.$store.dispatch('removeStyle', styleId)
      .then(() => {
        this.isLoading = false
        this.$delete(this.stylesList, styleId)
      })
  }

  fetchConfig(): void {
    this.isLoading = true
    const boardConfig = this.$store.state.boardsState.activeBoard

    if (boardConfig) {
      this.stylesList = cloneDeep(boardConfig.config.styles)

      this.isLoading = false
      this.isMounted = true
    }
  }

  mounted(): void {
    this.fetchConfig()
  }
}
